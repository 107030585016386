import carouselImg from '../Resources/CarouselImg.png';
const Slides = [
    {
        id: 1,
        url: carouselImg,
        text1: 'Welcome to teen TEKKA',
        text2: 'Purity in Every Drop',
        text3: 'Explore the Richness of Mustard Oil',
        btnText: 'Read More'
    },
    {
        id: 2,
        url: carouselImg,
        text1: 'Welcome to teen TEKKA',
        text2: 'Purity in Every Drop',
        text3: 'Explore the Richness of Mustard Oil',
        btnText: 'Read More'
    },
    {
        id: 3,
        url: carouselImg,
        text1: 'Welcome to teen TEKKA',
        text2: 'Purity in Every Drop',
        text3: 'Explore the Richness of Mustard Oil',
        btnText: 'Read More'
    },
    {
        id: 4,
        url: carouselImg,
        text1: 'Welcome to teen TEKKA',
        text2: 'Purity in Every Drop',
        text3: 'Explore the Richness of Mustard Oil',
        btnText: 'Read More'
    }
];


export default Slides;
import mustartOil from '../Resources/Mustard Oil.png';
import comboJar from '../Resources/Combojar.png';
import mp500 from '../Resources/500 ml mini Pouch.png';
import Jumbojar from '../Resources/Jumbojar.png';
const FeatureProducts = [
    {
        id: 1,
        url: mustartOil,
        productName: 'Mustard Oil Jar',
        rating: 4
    },
    {
        id: 3,
        url: mp500,
        productName: '500g Mini Pouch',
        rating: 4
    },
    {
        id: 4,
        url: Jumbojar,
        productName: 'MUSTARD OIL JUMBO JAR',
        rating: 4
    },
    {
        id: 3,
        url: mp500,
        productName: '500g Mini Pouch',
        rating: 4
    },
    {
        id: 1,
        url: mustartOil,
        productName: 'Mustard Oil Jar',
        rating: 4
    },


];
export default FeatureProducts;